import React from "react";
import { Dialog, DialogContent, Box, Typography } from "@mui/material";

const StylishLoadingDialog = ({ loading }) => {
  return (
    <Dialog
      open={loading}
      sx={{
       
        backdropFilter: "blur(5px)",
        ".MuiPaper-root": {
          backgroundColor: "transparent", 
          boxShadow: "none",
          overflow: "hidden", 
        },
      }}
    >
      <DialogContent
        sx={{ 
          backgroundColor: "transparent", 
          textAlign: "center",
          display: "flex",
          flexDirection: "column", 
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", 
          padding: 0, 
        }}
      >
        {/* Responsive Infinity Animation Loader */}
        <Box
          sx={{
            ...spinnerStyles,
            width: {
              xs: "300px", 
              sm: "350px",
              md: "400px", 
              lg: "400px", 
              xl: "400px", 
            },
            height: {
              xs: "300px", 
              sm: "350px", 
              md: "400px",
              lg: "400px", 
              xl: "400px", 
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
              display: "flex", 
              flexDirection: "column", 
              justifyContent: "center", 
              alignItems: "center", 
              width: "100%",
              height: "100%",
              margin: 0, 
              padding: 0, 
            }}
          >
            <img
              src="/assets/infinite-Loding-unscreen-ezgif.com-crop.gif"
              alt="loading"
              style={{
                width: "200px", 
                height: "150px", 
                opacity: 0.6,
                margin: 0, 
              }}
            />
            <Typography
              sx={{
                color: "#fff", 
                fontSize: "18px",
                marginTop: 0, 
                padding: 0, 
              }}
            >
              Loading...
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

// Styles for the container
const spinnerStyles = {
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 auto",
};

const keyframes = `
  @keyframes spinInfinity {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const injectKeyframes = () => {
  const styleSheet = document.styleSheets[0];
  styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
};

injectKeyframes();

export default StylishLoadingDialog;
