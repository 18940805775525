import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  CardMedia,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TranslateIcon from "@mui/icons-material/Translate";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useLocation } from "react-router-dom";
import { tokens } from "../../theme";
import axios from "axios";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ padding: 3 }}>{children}</Box>}
    </div>
  );
}

function loadScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => resolve(true);
    script.onerror = () => reject("Failed to load Razorpay SDK");
    document.body.appendChild(script);
  });
}

export default function CourseDetail() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const course = location.state?.course;
  const token = localStorage.getItem("token");

  console.log("demo", course);

  const [enrolled, setEnrolled] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleEnroll = async () => {
    try {
      const scriptLoaded = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!scriptLoaded) {
        alert("Razorpay SDK failed to load.");
        return;
      }

      const courseId = course.courseId;
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/razorpayOrder`,
        { courseId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log(data);
      const name = localStorage.getItem("username");
      const email = localStorage.getItem("userEmail");

      const options = {
        key: process.env.RAZORPAY_KEY,
        currency: data.currency,
        amount: course.coursedis,
        order_id: data.id,
        name: course.courseName,
        description: "Course Enrollment Fee",
        image: course.courseImage,
        notes: {
          courseId: courseId,
        },
        prefill: {
          name,
          email,
        },
        handler: (response) => {
          if (response.razorpay_payment_id) {
            alert("Payment Successful!");
            setEnrolled(true);
          }
        },
        modal: {
          ondismiss: () => {
            alert("Payment Failed");
          },
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      alert(`Error: ${error}`);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ padding: 5, mt: 5, minHeight: "100vh" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: colors.primary[400],
          borderRadius: 2,
          boxShadow: 3,
          padding: { xs: 3, sm: 5 },
        }}
      >
        <CardMedia
          component="img"
          image={course.courseImage}
          alt={course.courseName}
          sx={{
            height: "300px",
            width: { xs: "100%", sm: "45%" },
            objectFit: "cover",
            borderRadius: 2,
            boxShadow: 2,
            marginBottom: { xs: 2, sm: 0 },
          }}
        />

        <Box sx={{ flex: 1, ml: { sm: 4 }, mt: { xs: 2, sm: 0 } }}>
          <Typography
            variant="h2"
            sx={{ fontWeight: "bold", color: colors.primary[150] }}
            gutterBottom
          >
            {course.courseName}
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{
              color: colors.primary[150],
            }}
          >
            {course.courseDesc}
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              mb: 1,
            }}
          >
            <AccessTimeIcon />
            <Typography>
              <strong>Course Duration:</strong> {course.courseDuration}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
            <Typography
              variant="h2"
              sx={{ fontWeight: "bold", color: "green", mr: 2 }}
            >
              ₹{course.coursedis}
            </Typography>
            <Typography
              variant="h3"
              color="text.secondary"
              sx={{ textDecoration: "line-through" }}
            >
              ₹{course.courseAmount}
            </Typography>
          </Box>
          <Box sx={{ mt: 3 }}>
            {!enrolled ? (
              <Button
                variant="contained"
                size="large"
                sx={{
                  backgroundColor: "#B09246",
                  ":hover": { backgroundColor: "#a0813f" },
                  borderRadius: 3,
                  padding: "12px 24px",
                  fontWeight: "bold",
                  boxShadow: 2,
                }}
                onClick={handleEnroll}
              >
                Enroll Now
              </Button>
            ) : (
              <Typography variant="h3" color="success.main">
                Successfully Enrolled!
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      <Box sx={{ width: "100%", mt: 4 }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="fullWidth"
          aria-label="course details tabs"
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            color: colors.primary[150],
            "& .MuiTab-root": {
              fontWeight: "bold",
              color: colors.primary[150],
            },

            "& .MuiTabs-indicator": {
              backgroundColor: colors.greenAccent[300],
            },
          }}
        >
          <Tab
            label={
              <Typography variant="h4" fontWeight="bold"
              sx={{
                color:
                  theme.palette.mode === "light" ? "black" : "white",
              }}
              >
                Course Overview
              </Typography>
            }
          />
          <Tab
            label={
              <Typography variant="h4" fontWeight="bold"
              sx={{
                color:
                  theme.palette.mode === "light" ? "black" : "white",
              }}
              >
                FAQs
              </Typography>
            }
          />
        </Tabs>

        <TabPanel value={selectedTab} index={0}>
          <Typography
            variant="h3"
            sx={{ fontWeight: "bold", color: colors.primary[150] }}
            gutterBottom
          >
            Course Overview
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              alignItems: "center",
              backgroundColor: colors.primary[400],
              width: { xs: "120%", sm: "50%", md: "50%", lg: "40%" },
              height: "5vh",
              padding: "0 1rem",
              borderRadius: "10px",
            }}
          >
            <TranslateIcon />
            <Typography variant="h4">Course language</Typography>
            <Typography variant="h4" ml={"15%"}>
              English
            </Typography>
          </Box>

          <Typography
            variant="body1"
            color="text.secondary"
            paragraph
            sx={{ lineHeight: 1.7 }}
          >
            {course.courseOverview}
          </Typography>
        </TabPanel>

        <TabPanel value={selectedTab} index={1}>
          <Typography
            variant="h3"
            sx={{ fontWeight: "bold", color: colors.primary[150] }}
            gutterBottom
          >
            Frequently Asked Questions (FAQs)
          </Typography>
          {course.FAQ.map((faq, index) => (
            <Accordion
              key={index}
              elevation={0}
              sx={{
                m: 0,
                p: 0,
                backgroundColor: "transparent",
                "&:before": {
                  display: "none", // Removes the bottom divider line
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
                sx={{ color: colors.primary[150], paddingBottom: 0 }} // Remove extra padding on summary
              >
                <Typography variant="h4" sx={{ fontWeight: "bold", m: 0, p: 0 }}>
                  Q{index + 1}: {faq.faqTitle}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingTop: 0,marginTop: 0 }}>
                <Typography variant="h5" sx={{ color: colors.primary[150], ml: 3, mt: 0, p: 0}}>
                  {faq.faqAns}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </TabPanel>
      </Box>
    </Box>
  );
}
