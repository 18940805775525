import React, { useState, useEffect } from "react";
import Header from "../Header";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import StylishLoadingDialog from "../StatBox";
import { useLocation } from "react-router-dom";

export default function MyCourses() {
    const theme = useTheme();
    const navigate = useNavigate();
    const colors = tokens(theme.palette.mode);
    const token = localStorage.getItem("token");
  
    const [myCourseList, setMyCourseList] = useState([]);
    const [loading, setLoading] = useState(false);
  
    // Fetch user's courses data from API
    const fetchMyCourses = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/display/myCourses`,
          {},
          {
            headers: { Authorization: token },
          }
        );
        const courseMap = response.data?.courseMap?.[0]?.Course || [];
        setMyCourseList(courseMap);
      } catch (error) {
        console.error("Error fetching my courses:", error);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      fetchMyCourses();
    }, []); // Empty dependency array to fetch courses only once on mount
  
    const handleViewClick = (course) => {
      navigate(`/ShraddhaMemeberTV`,{ state: { course } });
    };
  
    return (
      <Box
        sx={{
          padding: 4,
          mt: "50px",
          minHeight: "100vh",
          
        }}
        onContextMenu={(e) => e.preventDefault()}
      >
        <Header title="My Courses" subtitle="View your enrolled courses" />
  
        <Grid container spacing={4}>
          {myCourseList.length > 0 ? (
            myCourseList.map((mycourse) => (
              <Grid item xs={12} sm={6} md={4} key={mycourse.course_Id}>
                <Card
                  sx={{
                    borderRadius: "15px",
                    backgroundColor: colors.primary[400],
                    boxShadow: 3,
                    transition: "transform 0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.05)",
                      boxShadow: 6,
                    },
                  }}
                >
                  <img
                    src={mycourse.courseImage}
                    alt={mycourse.courseName}
                    style={{
                      height: "200px",
                      width: "100%",
                      objectFit: "cover",
                      borderTopLeftRadius: "15px",
                      borderTopRightRadius: "15px",
                    }}
                  />
                  <CardContent>
                    <Typography variant="h5" gutterBottom>
                      {mycourse.courseName}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {mycourse.courseDesc}
                    </Typography>
                  </CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mb: 2,
                    }}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      sx={{
                        borderRadius: "10px",
                        backgroundColor: "#B09246",
                        ":hover": { backgroundColor: "#a0813f" },
                        width: "80%",
                        fontWeight: "bold",
                      }} 
                      onClick={() => handleViewClick(mycourse)}
                    >
                      View
                    </Button>
                  </Box>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography variant="h6" sx={{ textAlign: "center", width: "100%" }}>
              No courses found.
            </Typography>
          )}
        </Grid>
        <StylishLoadingDialog loading={loading} />
      </Box>
    );
  }
  