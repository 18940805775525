import { useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  Group as GroupIcon,
  Event as EventIcon,
  VideoChat as VideoChatIcon,
  CardMembership as CardMembershipIcon,
} from "@mui/icons-material";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import VideoFileIcon from '@mui/icons-material/VideoFile';
import "react-pro-sidebar/dist/css/styles.css";

const AdminSidebar = () => {
  const [selected, setSelected] = useState("Users");

  const handleSectionClick = (section) => {
    setSelected(section);
  };

  // This function checks if the section is selected
  const isSelected = (section) => selected === section;

  return (
    <Drawer
      sx={{
        "& .MuiDrawer-paper": {
          width: 240,
          boxSizing: "border-box",
          marginTop: "64px",
          height: "100vh",
          backgroundColor: "#E9F3F5",
          color: "#000000",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <List>
        {/* Users Section */}
        <Link to="/ShraddhaAdminUser">
          <ListItem
            onClick={() => handleSectionClick("Users")}
            sx={{
              "&:hover": { backgroundColor: "#F7F5EF" },
              backgroundColor: isSelected("Users") ? "#D0E8F5" : "transparent",
              marginBottom: "10px",
            }}
          >
            <ListItemIcon sx={{ color: "#000000" }}>
              <GroupIcon />
            </ListItemIcon>

            <ListItemText primary="Users" sx={{ color: "#000000" }} />
          </ListItem>
        </Link>

        {/* Infinite Member Section */}
        <Link to="/ShraddhaInfiniteMember">
          <ListItem
            onClick={() => handleSectionClick("Infinite Member")}
            sx={{
              "&:hover": { backgroundColor: "#F7F5EF" },
              backgroundColor: isSelected("Infinite Member")
                ? "#D0E8F5"
                : "transparent",
              marginBottom: "10px",
            }}
          >
            <ListItemIcon sx={{ color: "#000000" }}>
              <CardMembershipIcon />
            </ListItemIcon>

            <ListItemText primary="Infinite Member" sx={{ color: "#000000" }} />
          </ListItem>
        </Link>

        {/* Events Section */}
        <Link to="/ShraddhaAdminEvent">
          <ListItem
            onClick={() => handleSectionClick("Events")}
            sx={{
              "&:hover": { backgroundColor: "#F7F5EF" },
              backgroundColor: isSelected("Events") ? "#D0E8F5" : "transparent",
              marginBottom: "10px",
            }}
          >
            <ListItemIcon sx={{ color: "#000000" }}>
              <EventIcon />
            </ListItemIcon>

            <ListItemText primary="Events" sx={{ color: "#000000" }} />
          </ListItem>
        </Link>

        {/* Infinite Courses Section */}
        <Link to="/AdminCourses">
          <ListItem
            onClick={() => handleSectionClick("Courses")}
            sx={{
              "&:hover": { backgroundColor: "#F7F5EF" },
              backgroundColor: isSelected("Courses")
                ? "#D0E8F5"
                : "transparent",
              marginBottom: "10px",
            }}
          >
            <ListItemIcon sx={{ color: "#000000" }}>
              <OndemandVideoIcon />
            </ListItemIcon>

            <ListItemText primary="Courses" sx={{ color: "#000000" }} />
          </ListItem>
        </Link>

        {/* Training Videos Section */}
        <Link to="/ShraddhaAdminTraning">
          <ListItem
            onClick={() => handleSectionClick("Training Videos")}
            sx={{
              "&:hover": { backgroundColor: "#F7F5EF" },
              backgroundColor: isSelected("Training Videos")
                ? "#D0E8F5"
                : "transparent",
              marginBottom: "10px",
            }}
          >
            <ListItemIcon sx={{ color: "#000000" }}>
              <VideoChatIcon />
            </ListItemIcon>

            <ListItemText primary="Training Videos" sx={{ color: "#000000" }} />
          </ListItem>
        </Link>

        {/* Training Videos resource */}
        <Link to="/VideoResourses">
          <ListItem
            onClick={() => handleSectionClick("Videos Resources")}
            sx={{
              "&:hover": { backgroundColor: "#F7F5EF" },
              backgroundColor: isSelected("Videos Resources")
                ? "#D0E8F5"
                : "transparent",
              marginBottom: "10px",
            }}
          >
            <ListItemIcon sx={{ color: "#000000" }}>
              <VideoFileIcon />
            </ListItemIcon>

            <ListItemText primary="Videos Resources" sx={{ color: "#000000" }} />
          </ListItem>
        </Link>
      </List>
    </Drawer>
  );
};

export default AdminSidebar;

