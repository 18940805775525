import React from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import axios from "axios";

const Signupconfirm = () => {
  const userEmail = localStorage.getItem("regiEmail");

  const resendhandle = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/confirmation/resend`,
        {
          userEmail,
        }
      );
      alert("Mail Send successfully");
    } catch (error) {
      alert(
        error.response?.data?.message || error.message || "An error occurred"
      );
    }
  };

  return (
    // <Container component="main" maxWidth="sm" >
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundImage: "url('../../assets/sign up BG_equal.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Card
        sx={{
          width: "40%",
          textAlign: "center",
          boxShadow: 3,
          borderRadius: 2,
          padding: 3,
          backgroundColor:"white",
        }}
      >
        <CardContent>
          <img
            alt="logo"
            width="250px"
            height="120px"
            style={{ borderRadius: "15px" }}
            src="../../assets/Infinitelogo.png"
          />

          {/* Title */}
          <Typography variant="h2" fontWeight="bold" gutterBottom>
            Email Confirmation
          </Typography>
          <Typography variant="h2" fontWeight="bold" gutterBottom>
            Required
          </Typography>

          {/* Divider */}
          <Divider
            sx={{
              marginY: 2,
              width: "60%",
              mx: "auto",
            }}
          />

          {/* Message */}

          <Typography variant="body1" color="textSecondary" paragraph>
            We sent an email to <strong>{userEmail}</strong>. Please check your
            inbox to activate your account. If the email is not in your inbox,
            kindly check the Spam folder.
          </Typography>

          {/* Resend Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={resendhandle}
            sx={{
              marginTop: 2,
              paddingX: 3,
              paddingY: 1,
            }}
          >
            Resend Email
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Signupconfirm;
