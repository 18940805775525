import {
    Box,
    IconButton,
    Typography,
    useTheme,
    Popover,
    Button,
    Avatar,
    Divider,
  } from "@mui/material";
  import { useContext, useState, useEffect } from "react";
  import { ColorModeContext, tokens } from "../../theme";
  import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
  import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
  import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
  import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
  import ExitToAppIcon from "@mui/icons-material/ExitToApp";
  import { useNavigate } from "react-router-dom";
  import axios from "axios";
  
  const GauestTopbar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const navigate = useNavigate();
  
    const [userData, setUserData] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
  
    useEffect(() => {
      fetchUserData();
    }, []);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleLogout = async () => {
      const token = localStorage.getItem("guestToken"); 
      localStorage.removeItem("guestToken");
      localStorage.removeItem("cmpid");
      localStorage.removeItem("cmpname");
      navigate("/");
  
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/logout`, {
          token,
        });
      } catch (error) {
        console.log("your session are expired");
      }
    };
  
    const handlresetPwd = () => {
      navigate("/forgot-password");
    };
  
    const fetchUserData = async () => {
      const token = localStorage.getItem("guestToken"); 

      console.log("token",token)
  
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/guest/profile/info",
          {},
          {
            headers: { Authorization: token },
          }
        );
  
        setUserData(response.data.user[0]);
        const usersData = response.data.user[0];
        localStorage.setItem("VTContacId", usersData.vtiger_Contact_Id)
        localStorage.setItem("Role", usersData.role);
        const userName = usersData
          ? usersData.role === "admin"
            ? `${usersData.admin_FirstName} ${usersData.admin_LastName}`
            : usersData.user_Name
          : "User Name"; 
  
          localStorage.setItem("username", userName); 
          localStorage.setItem("userEmail",usersData.admin_Email) 
  
      } catch (err) {
        handleLogout();
        console.log("Error fetching user data");
      }
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? "user-popover" : undefined;
  
    const avatarLetter = userData
      ? userData.role === "admin"
        ? userData.admin_FirstName.charAt(0).toUpperCase()
        : userData.user_Name.charAt(0).toUpperCase()
      : "U";
    const userName = userData
      ? userData.role === "admin"
        ? `${userData.admin_FirstName} ${userData.admin_LastName}`
        : userData.user_Name
      : "User Name";
    const userEmail = userData
      ? userData.role === "admin"
        ? userData.admin_Email
        : userData.user_Email
      : "user@example.com";
  
    return (
      <Box
        width="100vw"
        marginLeft={0}
        display="flex"
        justifyContent="space-between"
        p={0.7}
        backgroundColor={colors.primary[400]}
        boxShadow={"1px 0px 2px rgba(0, 0, 0, 0.5)"}
        position={"fixed"}
        top={0}
        zIndex={1000}
      >
        {/* Left Side: Logo and Text */}
        <Box display="flex" alignItems="center" gap={{ xs: 0.5, sm: 1 }}>
          {/* Logo Section */}
          <Box
            component="img"
            src="../../assets/infinite.png"
            alt="Shraddha Infinite"
            sx={{
              height: {
                xs: "40px",
                sm: "40px",
                md: "50px",
                lg: "60px", // Increased height for larger screens
                xl: "60px", // Extra large screens
              },
              width: {
                xs: "55px",
                sm: "55px",
                md: "80px",
                lg: "120px", // Increased width for larger screens
                xl: "150px", // Extra large screens
              },
            }}
          />
  
          {/* Text Section */}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: { xs: "30px", sm: "30px", md: "50px" }, // Match the height of logo
            }}
          >
            <Typography
              variant="h3"
              sx={{
                 fontSize: { xs: "1.3rem", sm: "1.3rem", md: "1.5rem" },
                fontWeight: "bold",
                textTransform: "uppercase", // Strong impact
                marginBottom: 0, // Removes extra spacing below this Typography
              }}
            >
              Shraddha
            </Typography>
            <Typography
               variant="h3"
              sx={{
                //  fontSize: { xs: "1.2rem", sm: "1rem", md: "1.4rem" },
                color: "#B09246",
                textTransform: "uppercase", // Strong impact
                marginTop: 0, // Removes extra spacing above this Typography
              }}
            >
              Infinite
            </Typography>
          </Box>
        </Box>
  
        {/* ICONS */}
        <Box display="flex" alignItems="center" marginRight="50px">
          <Box ml={3}>
              <Button
                size="small"
                variant="contained"
                sx={{
                  color: "#F8F4B2",
                  backgroundColor: "#000",
                  ":hover": { backgroundColor: "#000" },
                  borderRadius: "10px",
                  fontSize: { sm: "1rem", md: "1.1rem" },
                }}
                onClick={() => window.open("https://forms.gle/vYdvWYphZEcWmNmJ8")}
              >
                Survey
              </Button>
          </Box>
          <IconButton
            onClick={colorMode.toggleColorMode}
            aria-label="Toggle Theme"
          >
            {theme.palette.mode === "dark" ? (
              <LightModeOutlinedIcon />
            ) : (
              <DarkModeOutlinedIcon />
            )}
          </IconButton>
          <IconButton onClick={handleClick} aria-label="User Profile">
            <PersonOutlinedIcon />
          </IconButton>
        </Box>
  
        {/* User Popup */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            style: {
              backgroundColor: colors.primary[400],
              color: colors.primary[100],
              borderRadius: "10px",
            },
          }}
        >
          <Box padding={2} textAlign="center">
            {/* Avatar */}
            <Box
              alignItems="center"
              justifyContent="center"
              style={{ padding: "10px", height: "100px" }}
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <Avatar
                  style={{
                    backgroundColor: "#EFE17C", //"#f39c12",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#000000",
                  }}
                >
                  {avatarLetter}
                </Avatar>
              </Box>
              <Box padding="10px">
                <Typography variant="h3" style={{ margin: "0" }}>
                  {userName}
                </Typography>
                <Typography
                  variant="h4"
                  color="textSecondary"
                  style={{ margin: "0" }}
                >
                  {userEmail}
                </Typography>
              </Box>
            </Box>
  
            <Divider style={{ margin: "10px 0", marginTop: "30px" }} />
  
            {/* Buttons */}
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap="12px"
            >
              <Box display="flex" alignItems="center">
                <SettingsOutlinedIcon style={{ color: colors.primary[100] }} />
                <Button variant="text" color="inherit" onClick={handlresetPwd}>
                  Change Password
                </Button>
              </Box>
              <Box display="flex" alignItems="center" marginRight="60px">
                <ExitToAppIcon style={{ color: colors.primary[100] }} />
                <Button variant="text" color="inherit" onClick={handleLogout}>
                  Logout
                </Button>
              </Box>
            </Box>
  
            <Divider style={{ margin: "16px 0" }} />
  
            {/* Privacy and policy */}
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="body2" color="textSecondary">
                Privacy Policy | Security Statement
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                style={{ marginTop: "8px" }}
              >
                © 2024 - 1.0.0
              </Typography>
            </Box>
          </Box>
        </Popover>
      </Box>
    );
  };
  
  export default GauestTopbar;
  