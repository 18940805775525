import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { DataGrid } from "@mui/x-data-grid";

import axios from "axios";
export default function AdminCourses() {
  const [CourseData, setCourseData] = useState([]);
  const token = localStorage.getItem("shraddhatoken");
  const [formValues, setFormValues] = useState({
    courseId: "",
    courseName: "",
    courseAmount: "",
    courseDesc: "",
    courseImage: "",
    courseOverview:"",
    courseDuration:"",
    coursedis:"",
  });
  const [ShowDialog, setShowDialog] = useState(false);

  const [isUpdate, setIsUpdate] = useState(false);

  const handleOpen = () => {
    setFormValues({
      courseName: "",
      courseAmount: "",
      courseDesc: "",
      courseImage: "",
      courseOverview:"",
      courseDuration:"",
      coursedis:"",
    });
    setIsUpdate(false);
    setShowDialog(true);
  };

  const Closedialog = () => setShowDialog(false);

  const handleDateChange = (field, value) => {
    setFormValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleUpdate = async () => {
    const updateCourse = {
      courseId: formValues.courseId,
      courseName: formValues.courseName,
      courseAmount: formValues.courseAmount,
      courseDesc: formValues.courseDesc,
      courseImage: formValues.courseImage,
      courseOverview:formValues.courseOverview,
      courseDuration:formValues.courseDuration,
      coursedis:formValues.coursedis,
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/shraddha/operation/courseUpdate",
        updateCourse,
        {
          headers: { Authorization: token },
        }
      );

      if (response) {
        alert(response.data.message);
        setShowDialog(false);
      }
    } catch (error) {
      alert("Error Update Course", error);
    }
  };

  const handleCreate = async () => {
    const newcourse = {
      courseName: formValues.courseName,
      courseAmount: formValues.courseAmount,
      courseDesc: formValues.courseDesc,
      courseImage: formValues.courseImage, //":"https://tallyeducation.com/teplwordpress/wp-content/uploads/2024/03/TallyPrime-with-GST.png"
      courseOverview:formValues.courseOverview,
      courseDuration:formValues.courseDuration,
      coursedis:formValues.coursedis,
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/shraddha/operation/courseCreate",
        newcourse,
        {
          headers: { Authorization: token },
        }
      );

      if (response) {
        alert(response.data.message);
        setShowDialog(false);
      }
    } catch (error) {
      alert("Error creating video", error);
    }
  };

  const LedfetchData = (userRow) => {
    setFormValues({
      courseId: userRow.id,
      courseName: userRow.courseName,
      courseAmount: userRow.courseAmount,
      courseDesc: userRow.courseDesc,
      courseImage: userRow.courseImage,
      courseOverview:userRow.courseOverview,
      courseDuration:userRow.courseDuration,
      coursedis:userRow.coursedis,
    });
    setIsUpdate(true);
    setShowDialog(true);
  };

  //function to delete select row
  const handleCourseDelete = async (userRow) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/shraddha/operation/courseDelete",
        { courseId: userRow.id },
        {
          headers: { Authorization: token },
        }
      );

      if (response) {
        alert(response.data.message);
      }
    } catch (error) {
      alert("Error Deleting Course", error);
    }
  };

  // ====================== Show Courses Data handler ========================

  const fetchCourseData = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/shraddha/operation/courselist",
        {},
        {
          headers: { Authorization: token },
        }
      );
      const users = Object.values(response.data.courseMap[0].Course);
      setCourseData(users);
    } catch (error) {
      console.log("Error fetching group data", error);
    }
  };

  useEffect(() => {
    fetchCourseData();
  }, []);

  const courseListRow = CourseData.map((course) => ({
    id: course.courseId,
    courseName: course.courseName,
    courseDesc: course.courseDesc,
    courseDuration: course.courseDuration,
    courseOverview: course.courseOverview,
    courseImage: course.courseImage,
    courseAmount: course.courseAmount,
    coursedis:course.coursedis,
  }));

  const columns = [
    {
      field: "Sr No",
      headerName: "Sr No",
      headerAlign: "center",
      align: "center",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => params.api.getRowIndex(params.id) + 1,
    },
    {
      field: "courseName",
      headerName: "Course Title",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "courseDesc",
      headerName: "Descriptions",
      headerAlign: "center",
      align: "center",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "courseDuration",
      headerName: "Durations",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "courseOverview",
      headerName: "Overview ",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "courseImage",
      headerName: "Image Link",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    
    {
      field: "coursedis",
      headerName: "Discount Amount",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    
    
    {
      field: "courseAmount",
      headerName: "Amount",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },

    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box>
            <Button
              variant="contained"
              style={{
                backgroundColor: "gray",
                color: "ffffff",
                marginRight: "10px",
                "&:hover": {
                  backgroundColor: "gray",
                  color: "ffffff",
                },
              }}
              size="small"
              type="button"
              onClick={() => LedfetchData(params.row)}
            >
              Update
            </Button>

            <Button
              variant="contained"
              style={{
                backgroundColor: "red",
                color: "ffffff",
                "&:hover": {
                  backgroundColor: "red",
                  color: "ffffff",
                },
              }}
              size="small"
              type="button"
              onClick={() => handleCourseDelete(params.row)}
            >
              Delete
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        paddingTop: 8,
        paddingLeft: 3,
        paddingRight: 3,
        marginLeft: "240px",
        marginTop: 4,
        backgroundColor: "#f4f6f7",
        borderRadius: "10px",
      }}
    >
   
      <Dialog
        open={ShowDialog}
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "#f0f0f0",
            padding: 2,
          },
        }}
      >
        <DialogTitle
          variant="h3"
          gutterBottom
          p={0}
          m={0}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "#000",
          }}
        >
          {isUpdate ? "Update Course Details" : "Add New Course"}

          <CancelIcon
            sx={{
              cursor: "pointer",
              fontSize: "24px", // Adjust size if necessary
            }}
            onClick={Closedialog}
          />
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2} mt={0}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Course Title"
                name="Name"
                size="small"
                fullWidth
                value={formValues.courseName}
                onChange={(e) => handleDateChange("courseName", e.target.value)}
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Durations"
                name="Durations"
                size="small"
                fullWidth
                value={formValues.courseDuration}
                onChange={(e) =>
                  handleDateChange("courseDuration", e.target.value)
                }
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Descriptions"
                name="Descriptions"
                size="small"
                fullWidth
                value={formValues.courseDesc}
                onChange={(e) => handleDateChange("courseDesc", e.target.value)}
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="OverView"
                name="OverView"
                size="small"
                fullWidth
                value={formValues.courseOverview}
                onChange={(e) => handleDateChange("courseOverview", e.target.value)}
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={0}>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Image link"
                name="Image link"
                size="small"
                fullWidth
                value={formValues.courseImage}
                onChange={(e) =>
                  handleDateChange("courseImage", e.target.value)
                }
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Discount Amount"
                name="Durations"
                size="small"
                fullWidth
                value={formValues.coursedis}
                onChange={(e) =>
                  handleDateChange("coursedis", e.target.value)
                }
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Amount"
                name="Amount"
                size="small"
                fullWidth
                value={formValues.courseAmount}
                onChange={(e) =>
                  handleDateChange("courseAmount", e.target.value)
                }
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          {/* <Grid container spacing={2} mt={0}>
            
           </Grid> */}
        </DialogContent>

        <DialogActions
          sx={{ display: "flex", justifyContent: "right", padding: 1 }}
        >
          <Button
            size="small"
            variant="contained"
            onClick={Closedialog}
            sx={{ borderRadius: "10px", background: "red" }}
          >
            Cancel
          </Button>

          <Button
            color="success"
            size="small"
            variant="contained"
            onClick={isUpdate ? handleUpdate : handleCreate}
            sx={{
              borderRadius: "10px",
              backgroundColor: "green", // Set background color to green
              color: "white", // Set text color to white
              "&:hover": {
                backgroundColor: "darkgreen", // Optional: Darker green on hover
              },
            }}
          >
            {isUpdate ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>

      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h2" color="#000">
            All Courses Details
          </Typography>
          <Button
            variant="contained"
            size="small"
            sx={{
              borderRadius: "10px",
              backgroundColor: "green", // Set background color to green
              color: "white", // Set text color to white
              "&:hover": {
                backgroundColor: "darkgreen", // Optional: Darker green on hover
              },
            }}
            type="button"
            onClick={handleOpen}
          >
            + Add Course
          </Button>
        </Box>
        <Box
          height="74vh"
          sx={{
            overflowX: "auto",
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
              color: "black", // Set cell text color to black
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#E9F3F5",
              borderBottom: "none",
              cursor: "default",
              color: "black", // Set header text color to black
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
              boxShadow: "none",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              outline: "none",
              boxShadow: "none",
            },
            "& .MuiDataGrid-columnHeader:focus-visible": {
              outline: "none",
              boxShadow: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: "#fff", // Background color for the scroller
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: "#E9F3F5",
            },
            "& .MuiDataGrid-overlay": {
              backgroundColor: "#ffffff", // Set overlay background color when table is empty
              color: "gray", // Optional: set text color for empty state message
            },
          }}
        >
          <DataGrid
            rows={courseListRow}
            columns={columns}
            disableColumnSort={true}
          />
        </Box>
      </Box>
    </Box>
  );
}
