import { useMediaQuery } from "@mui/material";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { Routes, Route, useLocation } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import MemTopbar from "./scenes/global/MemberTopBar";
import Dashboard from "./scenes/dashboard";
import Sidebar from "./scenes/global/Sidebar";
import GauestSidebar from "./scenes/global/GauestSidebar";
import MemberSidebar from "./scenes/global/MemberSideBar";
import AdminSidebar from "./scenes/global/AdminSidebar";
import AdminTopbar from "./scenes/global/AdminTopbar"; 
import GauestTopbar from "./scenes/global/GauestTopbar"
import Team from "./scenes/team";
import TallyCompanies from "./components/Tally-Companies/TallyCompanies";
import PrimaryApprov from "./components/Voucher-Approval/primary-Approval";
import SecondaryApprov from "./components/Voucher-Approval/secondary-Approval";
import FinalApprov from "./components/Voucher-Approval/final-Approval";
import AdminList from "./components/Voucher-Approval/admin-Report";
import ApprovalModuleUsers from "./components/Approval-Module-Users/Approval-Module-Users";
import MarketPlace from "./components/Market_Place/MarketPlace";
import ModuleDetailPage from "./components/Market_Place/ModuleDetailPage";
import ResetPwd from "./authpages/ResetPassword";
import Forgotpassword from "./authpages/Forgotpassword";
import Signup from "./authpages/Signup";
import Confirmation from "./authpages/Confirmation";
import Signupconfirm from "./authpages/Signupconfirm";
import Login from "./authpages/Login";
import PayableReport from "./components/Reports/PayableReport";
import ReceivableReport from "./components/Reports/ReceivableReport";
import Ledger from "./components/TallyData/Ledger";
import VoucherEntry from "./components/Reports/VoucherEntry";
import VoucherCreate from "./components/Reports/VoucherCreate";
import CreditorScore from "./components/Reports/CreditorScore";
import DebitorScore from "./components/Reports/DebitorScore";
import ShraddhaAdminUser from "./components/Admin/AdminUser";
import ShraddhaAdmin from "./components/Admin/ShraddhaAdmin";
import AdminCourses from "./components/Admin/AdminCourses";
import ShraddhaAdminTraning from "./components/Admin/TrainingVideos";
import ShraddhaInfiniteMember from "./components/Admin/InfiniteMember";
import ShraddhaAdminEvent from "./components/Admin/AdminEvent";
import ShraddhaEvents from "./components/Events/Events";
import LearningAndEvents from "./components/support/Support";
import ShraddhaMemeberTV from "./components/courses/CourseVideo";
import CourseDetail from "./components/courses/CourseDetilas";
import ShraddhaInfiniteCourses from "./components/courses/Courses";
import MyCourses from "./components/courses/MyCourses";
import ShraddhaInfinite from "./components/Events/ShraddhaInfinite";
import ShraddhaMobileMenuBar from "./scenes/global/MobileMenuBar";
import { Navigate } from "react-router-dom";
import GauestEvents from "./components/Events/GauestEvent";
import TrainingVideos from "./components/Training Videos/TrainingVideos";
import VideoResourses from "./components/Admin/VideoResourses";
import ModuleConfig from "./components/Module Config/ModuleConfig";
// import { Margin } from "@mui/icons-material";

function App() {

  const [theme, colorMode] = useMode();
  const location = useLocation();
  const isMobileView = useMediaQuery("(max-width: 900px)");
  const isAuthenticated = localStorage.getItem("token") !== null;

  const PrivateRoute = ({ element, isAuthenticated }) => {
    return isAuthenticated ? element : <Navigate to="/" />;
  };

  const isAuthpage =
    [
      "/",
      "/Signupconfirm",
      "/confirmation",
      "/signup",
      "/forgot-password",
      "/ShraddhaAdmin",
    ].includes(location.pathname) &&
    location.pathname.includes("/reset-password");

  const isAdminPage = [
    "/ShraddhaAdminUser",
    "/ShraddhaAdminTraning",
    "/ShraddhaAdminEvent",
    "/ShraddhaInfiniteMember",
    "/AdminCourses",
    "/VideoResourses",
  ].includes(location.pathname);

  const isMemberpage = [
    "/ShraddhaEvents",
    "/LearningAndEvents",
    "/ShraddhaCourses",
    "/ShraddhaInfinite",
    "/ShraddhaMemeberTV",
    "/course-detail",
    "/ShraddhaMobileMenuBar",
    "/MyCourses",
    "/EventsRecording",

  ].includes(location.pathname);

  const isGauest = [
    "/GauestEvents"
  ].includes(location.pathname); 

  const isInfintePage=[
    "/team",
    "/TallyCompanies",
    "/Dashboard",
    "/PrimaryApprov",
    "/SecondaryApprov",
    "/FinalApprov",
    "/AdminList",
    "/ApprovalModuleUsers",
    "/MarketPlace",
    "/ModuleDetailPage/:id",
    "/Ledger",
    "/VoucherEntry",
    "/VoucherCreate",
    "/ReceivableReport",
    "/PayableReport",
    "/CreditorScore",
    "/DebitorScore",
    "/ModuleConfig",
  ].includes(location.pathname); 



  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content" >

            {/* Topbar For Infinite  */}
            {isInfintePage && (<Topbar />)}

            {/* Tobar for Menbers  */}
            {isMemberpage && <MemTopbar />}

            {/* Topbar for Admin */}
            {isAdminPage && <AdminTopbar />}

            {/* Topbar for mobile  */}
            {isMobileView && isMemberpage && <ShraddhaMobileMenuBar />} 
        

            {/* {isMobileView && isGauest && <ShraddhaMobileMenuBar />}  */}
            
            {isGauest && <GauestTopbar />}
             

            <div className="layout">

              {/* Sidebar fo Infinite */}
              {isInfintePage && !isMobileView && <Sidebar />}

              {/* sidebar for member */}
              {isMemberpage && !isMobileView && <MemberSidebar />}

              {/* Sidebar for Admin */}
              {isAdminPage && <AdminSidebar />}

              {/* Sidebar for Gauest */}
              {isGauest &&  !isMobileView &&<GauestSidebar />}


              {/* Routes for the application */}
              <div className="route-container"
            //  style={{ marginTop: "10vh" }}
            >
                <div className="loginRoute">
                  {" "}
                  <Routes>
                    <Route path="/ShraddhaInfinite" element={<ShraddhaInfinite />} />
                    <Route path="/ShraddhaCourses" element={<ShraddhaInfiniteCourses />} />
                    <Route path="/MyCourses" element={<MyCourses />} />
                    <Route path="/GauestEvents" element={<GauestEvents />} />
                    <Route path="/ShraddhaMemeberTV" element={<ShraddhaMemeberTV />} />
                    <Route path="/course-detail" element={<CourseDetail />} />
                    <Route path="/LearningAndEvents" element={<LearningAndEvents />} />
                    <Route path="/EventsRecording" element={<TrainingVideos />} />
                    <Route path="/VideoResourses" element={<VideoResourses />} />
                    
                    <Route path="/ShraddhaEvents" element={<ShraddhaEvents />} />
                    <Route path="/ShraddhaAdmin" element={<ShraddhaAdmin />} />
                    <Route path="/ShraddhaAdminUser" element={<ShraddhaAdminUser />} />
                    <Route path="/ShraddhaAdminEvent" element={<ShraddhaAdminEvent />} />
                    <Route path="/AdminCourses" element={<AdminCourses />} />
                    <Route path="/ShraddhaAdminTraning" element={<ShraddhaAdminTraning />} />
                    <Route path="/ShraddhaInfiniteMember" element={<ShraddhaInfiniteMember />} />
                    <Route path="/" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/Signupconfirm" element={<Signupconfirm />} />
                    <Route path="/confirmation" element={<Confirmation />} />
                    <Route path="/forgot-password" element={<Forgotpassword />} />
                    <Route path="/reset-password" element={<ResetPwd />} />
                  </Routes>
                </div>

                <div className="mainRoute">
                  <Routes>
                   
                      {/* <Route path="/Dashboard" element={<PrivateRoute element={<Dashboard />} isAuthenticated={isAuthenticated} />} />  */}
                      {/* <Route path="*" element={<NotFoundPage />} /> */}
                      {/* <Route path="/team" element={<PrivateRoute element={<Team />} isAuthenticated={isAuthenticated} />} />
                      <Route path="/TallyCompanies" element={<PrivateRoute element={<TallyCompanies />} isAuthenticated={isAuthenticated} />} />
                      <Route path="/PrimaryApprov" element={<PrivateRoute element={<PrimaryApprov />} isAuthenticated={isAuthenticated} />} />
                      <Route path="/SecondaryApprov" element={<PrivateRoute element={<SecondaryApprov />} isAuthenticated={isAuthenticated} />} />
                      <Route path="/FinalApprov" element={<PrivateRoute element={<FinalApprov />} isAuthenticated={isAuthenticated} />} />
                      <Route path="/AdminList" element={<PrivateRoute element={<AdminList />} isAuthenticated={isAuthenticated} />} />
                      <Route path="/ApprovalModuleUsers" element={<PrivateRoute element={<ApprovalModuleUsers />} isAuthenticated={isAuthenticated} />} />
                      <Route path="/MarketPlace" element={<PrivateRoute element={<MarketPlace />} isAuthenticated={isAuthenticated} />} />
                      <Route path="/ModuleDetailPage/:id" element={<PrivateRoute element={<ModuleDetailPage />} isAuthenticated={isAuthenticated} />} />
                      <Route path="/Ledger" element={<PrivateRoute element={<Ledger />} isAuthenticated={isAuthenticated} />} />
                      <Route path="/VoucherEntry" element={<PrivateRoute element={<VoucherEntry />} isAuthenticated={isAuthenticated} />} />
                      <Route path="/VoucherCreate" element={<PrivateRoute element={<VoucherCreate />} isAuthenticated={isAuthenticated} />} />
                      <Route path="/ReceivableReport" element={<PrivateRoute element={<ReceivableReport />} isAuthenticated={isAuthenticated} />} />
                      <Route path="/PayableReport" element={<PrivateRoute element={<PayableReport />} isAuthenticated={isAuthenticated} />} />
                      <Route path="/CreditorScore" element={<PrivateRoute element={<CreditorScore />} isAuthenticated={isAuthenticated} />} />
                      <Route path="/DebitorScore" element={<PrivateRoute element={<DebitorScore />} isAuthenticated={isAuthenticated} />} />
                      <Route path="/ModuleConfig" element={<PrivateRoute element={<ModuleConfig />} isAuthenticated={isAuthenticated} />} />
                       */}
                   
                  </Routes>
                </div>
              </div>
            </div>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;

  document.addEventListener('contextmenu', (e) => e.preventDefault());
