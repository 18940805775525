import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";

export default function ShraddhaAdminTraning() {
  const [UsersData, setUsersData] = useState([]);
  const [videocourseId, setVideocourseId] = useState("");
  const token = localStorage.getItem("shraddhatoken");
  const [formValues, setFormValues] = useState({
    id: "",
    name: "",
    Description: "",
    Topic: "",
    Speaker: "",
    Category: "",
    SubCategory: "",
    VideoLink: "",
    courseId: "",
  });
  const [ShowDialog, setShowDialog] = useState(false);
  const [CourseData, setCourseData] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);

  const handleOpen = () => {
    setFormValues({
      id: "",
      name: "",
      Description: "",
      Topic: "",
      Speaker: "",
      Category: "",
      SubCategory: "",
      VideoLink: "",
      courseId: "",
    });
    setIsUpdate(false);
    setShowDialog(true);
  };

  const Closedialog = () => setShowDialog(false);

  const handleDateChange = (field, value) => {
    setFormValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleUpdate = async () => {
    const updateVideo = {
      videoId: formValues.id,
      videoURL: formValues.VideoLink,
      videoName: formValues.name,
      videoDesc: formValues.Description,
      videoCategory: formValues.Category,
      videoSubCategory: formValues.SubCategory,
      videoTopic: formValues.Topic,
      VideoSpeaker: formValues.Speaker,
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/shraddha/operation/videoUpdate",
        updateVideo,
        {
          headers: { Authorization: token },
        }
      );

      if (response) {
        alert(response.data.message);
        setShowDialog(false);
      }
    } catch (error) {
      alert("Error Update Account", error);
    }
  };

  const handleCreate = async () => {
    const newVideo = {
      videoURL: formValues.VideoLink,
      videoName: formValues.name,
      videoDesc: formValues.Description,
      videoCategory: formValues.Category,
      videoSubCategory: formValues.SubCategory,
      videoTopic: formValues.Topic,
      VideoSpeaker: formValues.Speaker,
      courseId: videocourseId,
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/shraddha/operation/videoCreate",
        newVideo,
        {
          headers: { Authorization: token },
        }
      );

      if (response) {
        alert(response.data.message);
        setShowDialog(false);
      }
    } catch (error) {
      alert("Error creating video", error);
    }
  };

  const LedfetchData = (userRow) => {
    setFormValues({
      id: userRow.id,
      name: userRow.name,
      Description: userRow.Description,
      Topic: userRow.Topic,
      Speaker: userRow.Speaker,
      Category: userRow.Category,
      SubCategory: userRow.SubCategory,
      VideoLink: userRow.VideoLink,
      courseId: userRow.courseId,
    });
    setIsUpdate(true);
    setShowDialog(true);
  };

  //function to delete select row
  const handleDelete = async (userRow) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/shraddha/operation/videoDelete",
        { videoId: userRow.id },
        {
          headers: { Authorization: token },
        }
      );

      if (response) {
        alert(response.data.message);
      }
    } catch (error) {
      alert("Error creating video", error);
    }
    alert("Deleted successfull");
  };

  const fetchUsersData = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/shraddha/operation/videoList",
        { courseId: videocourseId },
        {
          headers: { Authorization: token },
        }
      );
      const users = Object.values(response.data);
      setUsersData(users[0]);
    } catch (error) {
      console.log("Error fetching group data", error);
    }
  };

  const fetchCourseData = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/shraddha/operation/courselist",
        {},
        {
          headers: { Authorization: token },
        }
      );
      const users = Object.values(response.data.courseMap[0].Course);
      setCourseData(users);
    } catch (error) {
      console.log("Error fetching group data", error);
    }
  };

  const handleCourseChange = (event) => {
    const courseId = event.target.value;
    setVideocourseId(courseId);
    fetchUsersData();
  };

  useEffect(() => {
    fetchUsersData();
    fetchCourseData();
  }, []);

  const userListRow = UsersData.map((video, index) => ({
    id: video.video_Id,
    name: video.video_Name,
    Description: video.video_Desc,
    Topic: video.video_Topic,
    Speaker: video.Video_Speaker,
    Category: video.video_Category,
    SubCategory: video.video_SubCategory,
    VideoLink: video.video_URL,
  }));

  const columns = [
    {
      field: "",
      headerName: "SR No",
      headerAlign: "center",
      align: "center",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => params.api.getRowIndex(params.id) + 1,
    },
    {
      field: "name",
      headerName: "name",
      headerAlign: "center",
      align: "center",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "Description",
      headerName: "description",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "Topic",
      headerName: "Topic",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "Speaker",
      headerName: "Speaker",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "Category",
      headerName: "Category",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "SubCategory",
      headerName: "SubCategory",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div>
            <Button
              variant="contained"
              style={{
                backgroundColor: "gray",
                color: "ffffff",
                marginRight: "10px",
                "&:hover": {
                  backgroundColor: "gray",
                  color: "ffffff",
                },
              }}
              size="small"
              type="button"
              onClick={() => LedfetchData(params.row)}
            >
              Update
            </Button>

            <Button
              variant="contained"
              style={{
                backgroundColor: "red",
                color: "ffffff",
                "&:hover": {
                  backgroundColor: "red",
                  color: "ffffff",
                },
              }}
              size="small"
              type="button"
              onClick={() => handleDelete(params.row)}
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        paddingTop: 8,
        paddingLeft: 3,
        paddingRight: 3,
        marginLeft: "240px",
        marginTop: 4,
        backgroundColor: "#f4f6f7",
        borderRadius: "10px",
      }}
    >
      {/* ================== create and updated videos ===================== */}
      <Dialog
        open={ShowDialog}
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "#f0f0f0",
            padding: 2,
          },
        }}
      >
        <DialogTitle
          variant="h3"
          gutterBottom
          p={0}
          m={0}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "#000",
          }}
        >
          {isUpdate ? "Update Video Details" : "Add New Video"}
          <CancelIcon
            sx={{
              cursor: "pointer",
              fontSize: "24px", // Adjust size if necessary
            }}
            onClick={Closedialog}
          />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} mt={0}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Name"
                name="Name"
                size="small"
                fullWidth
                value={formValues.name}
                onChange={(e) => handleDateChange("name", e.target.value)}
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Description"
                name="Description"
                size="small"
                fullWidth
                value={formValues.Description}
                onChange={(e) =>
                  handleDateChange("Description", e.target.value)
                }
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={0}>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Topic"
                name="Topic"
                size="small"
                fullWidth
                value={formValues.Topic}
                onChange={(e) => handleDateChange("Topic", e.target.value)}
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Speaker"
                name="Speaker"
                size="small"
                fullWidth
                value={formValues.Speaker}
                onChange={(e) => handleDateChange("Speaker", e.target.value)}
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={0}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Category"
                name="Category"
                size="small"
                fullWidth
                value={formValues.Category}
                onChange={(e) => handleDateChange("Category", e.target.value)}
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Sub Category"
                name="SubCategory"
                size="small"
                fullWidth
                value={formValues.SubCategory}
                onChange={(e) =>
                  handleDateChange("SubCategory", e.target.value)
                }
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={0}>
            <Grid item xs={12}>
              <TextField
                label="Video Link"
                name="VideoLink"
                size="small"
                fullWidth
                value={formValues.VideoLink}
                onChange={(e) => handleDateChange("VideoLink", e.target.value)}
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          sx={{ display: "flex", justifyContent: "right", padding: 1 }}
        >
          <Button
            size="small"
            variant="contained"
            onClick={Closedialog}
            sx={{ borderRadius: "10px", background: "red" }}
          >
            Cancel
          </Button>

          <Button
            color="success"
            size="small"
            variant="contained"
            onClick={isUpdate ? handleUpdate : handleCreate}
            sx={{
              borderRadius: "10px",
              backgroundColor: "green", // Set background color to green
              color: "white", // Set text color to white
              "&:hover": {
                backgroundColor: "darkgreen", // Optional: Darker green on hover
              },
            }}
          >
            {isUpdate ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>

      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h2" color="#000">
            All Training Videos
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "70%",
            }}
          >
            <FormControl
              fullWidth
              size="small"
              sx={{
                width: "40%", // Apply 40% width
                mr: 2,
              }}
            >
              <InputLabel id="course-label" sx={{ color: "black" }}>
                Select Course
              </InputLabel>
              <Select
                name="courseId" // State key for course ID
                labelId="course-label"
                value={videocourseId}
                onChange={handleCourseChange} // Update state on change
                label="Select Course"
                fullWidth
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Input text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Focused border color
                    },
                  },
                }}
              >
                {CourseData.map((course) => (
                  <MenuItem key={course.courseId} value={course.courseId}>
                    {course.courseName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              variant="contained"
              size="small"
              sx={{
                borderRadius: "10px",
                backgroundColor: "green", // Set background color to green
                color: "white", // Set text color to white
                "&:hover": {
                  backgroundColor: "darkgreen", // Optional: Darker green on hover
                },
              }}
              type="button"
              onClick={handleOpen}
            >
              + Add Video
            </Button>
          </Box>
        </Box>
        <Box
          height="74vh"
          sx={{
            overflowX: "auto",
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
              color: "black", // Set cell text color to black
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#E9F3F5",
              borderBottom: "none",
              cursor: "default",
              color: "black", // Set header text color to black
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
              boxShadow: "none",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              outline: "none",
              boxShadow: "none",
            },
            "& .MuiDataGrid-columnHeader:focus-visible": {
              outline: "none",
              boxShadow: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: "#fff", // Background color for the scroller
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: "#E9F3F5",
            },
            "& .MuiDataGrid-overlay": {
              backgroundColor: "#ffffff", // Set overlay background color when table is empty
              color: "gray", // Optional: set text color for empty state message
            },
          }}
        >
          <DataGrid
            rows={userListRow}
            columns={columns}
            disableColumnSort={true}
          />
        </Box>
      </Box>
    </Box>
  );
}

// document.addEventListener('contextmenu', (e) => e.preventDefault());
