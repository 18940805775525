import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Divider,
  Grid,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Custom styled TextField component to match the style
const SignupTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputLabel-root": { color: "#000" }, // Label text color black
  "& .MuiInputLabel-root.Mui-focused": { color: "#000" }, // Label text color black on focus
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    "& fieldset": {
      borderColor: "#000", // Border color black
    },
    "&:hover fieldset": {
      borderColor: "#000", // Border color black on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#000", // Border color black on focus
    },
  },
  "& .MuiOutlinedInput-input": { color: "#000" }, // Input text color black
  "& .MuiFormControlLabel-label": {
    color: "#000", // Label text color black
  },
  "& .MuiInputBase-root": {
    backgroundColor: "white",
  },
  "& .MuiInputBase-input": {
    color: "black",
  },
  "& .MuiInputBase-input:-webkit-autofill": {
    WebkitBoxShadow: "0 0 0px 1000px white inset",
    WebkitTextFillColor: "black",
  },
}));

const validatePassword = (password) => {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  return (
    password.length >= minLength &&
    hasUpperCase &&
    hasLowerCase &&
    hasSpecialChar
  );
};

const Signup = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [cmpName, setCmpName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [mobile, setUserMobile] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [error, setError] = useState("");
  const [CheckNo, setCheckNo] = useState("");
  const navigate = useNavigate();
  const [ShowDialog, setShowDialog] = useState(false);
  const [emailError, setEmailError] = useState("");
  const Closedialog = () => setShowDialog(false);

  const getIpAddress = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      return response.data.ip;
    } catch (error) {
      console.error("Error fetching IP address", error);
      return null;
    }
  };

  const getUserAgent = () => {
    return navigator.userAgent;
  };

  ///==================================
  // Email validation function
  const validateEmail = (email) => {
    // Regular expression for basic email format validation
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setUserEmail(value);

    if (!validateEmail(value)) {
      setEmailError("Please enter a valid email Format.");
    } else {
      setEmailError(""); // Clear error if email is valid
    }
  };

  //===============================

  const handleRegister = async (e) => {
    e.preventDefault();
    const valid = validatePassword(password);
    setIsValid(valid);

    if (!valid) {
      return;
    }
    if (
      !userEmail ||
      !password ||
      !cmpName ||
      !firstname ||
      !lastname ||
      !mobile
    ) {
      setMessage("All fields are required.");
      return;
    }
    if (!termsChecked) {
      setError("You must agree to the terms and conditions");
      return;
    }

    if (isNaN(mobile) && mobile !== "") {
      setCheckNo("Please enter only numeric characters.");
      return;
    }

    setLoading(true);
    setMessage("");

    const ipAddress = await getIpAddress();
    const userAgent = getUserAgent();

    console.log("Ip and Agent", ipAddress, userAgent);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/auth/registration",
        {
          password,
          userEmail,
          firstname,
          lastname,
          cmpName,
          mobile,
          ipAddress,
          userAgent,
        }
      );

      if (response.data.exists) {
        setMessage(response.data.message);
      } else {
        setMessage(response.data.message);
        localStorage.setItem("regiEmail", userEmail);
        setFirstname("");
        setLastname("");
        setCmpName("");
        setUserEmail("");
        setUserMobile("");
        setPassword("");
        navigate("/Signupconfirm");
      }
    } catch (error) {
      setMessage(
        `Error during registration: ${
          error.response?.data?.message || error.message
        }`
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (event) => {
    setTermsChecked(event.target.checked);
    setError("");
  };
  const handleClickTc = () => {
    setShowDialog(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundImage: "url('../../assets/sign up BG_equal.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: 2,
      }}
    >
      {/* ========================================================== */}

      <Dialog
        open={ShowDialog}
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "#f0f0f0",
            // padding: 2,
          },
        }}
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                color: "black",
                marginBottom: "20px",
                alignItems: "center",
                fontWeight: "bolder",
              }}
            >
              Terms and Conditions
            </Typography>
          </Box>
          <Box
            sx={{
              maxHeight: 390,
              overflowY: "auto",
              padding: 2,
              backgroundColor: "#fff",
              borderRadius: 1,
              border: "1px solid #ccc",
            }}
          >
            <Typography variant="body2" sx={{ color: "black" }}>
              Welcome to Shraddha Infinite ("the Platform"). These Terms and
              Conditions ("Terms") govern your access to and use of the Platform
              and its services. By registering an account or using the Platform,
              you agree to be bound by these Terms. If you do not agree, please
              refrain from using the Platform.
              <br />
              <br />
              <strong>1. Eligibility</strong>
              <br />
              To use Shraddha Infinite, you must be at least 18 years old and
              capable of entering into a legally binding agreement. By signing
              up, you confirm that the information provided is accurate and
              complete.
              <br />
              <br />
              <strong>2. Account Registration</strong>
              <br />
              You are required to provide the following information during
              registration:
              <ul>
                <li>First Name</li>
                <li>Last Name</li>
                <li>Email ID</li>
                <li>Mobile Number</li>
                <li>Company Name</li>
                <li>Password</li>
              </ul>
              Your password is encrypted and securely stored. You are
              responsible for maintaining the confidentiality of your login
              credentials. Sharing or transferring your login details to third
              parties is strictly prohibited.
              <br />
              <br />
              <strong>3. Membership and Paid Services</strong>
              <br />
              Shraddha Infinite offers access to membership content, including
              but not limited to online events, training videos, and support
              services. Membership is a paid service, and all fees are
              non-refundable. By subscribing, you agree to make timely payments
              as per the selected plan.
              <br />
              <br />
              <strong>4. Prohibited Activities</strong>
              <br />
              You agree not to engage in the following activities:
              <ul>
                <li>Sharing, transferring, or misusing login credentials.</li>
                <li>
                  Accessing, distributing, or copying membership content without
                  authorization.
                </li>
                <li>Violating any applicable laws or regulations.</li>
              </ul>
              Shraddha Infosystems reserves the right to terminate your account
              without notice if these activities are detected.
              <br />
              <br />
              <strong>5. Intellectual Property</strong>
              <br />
              All content available on the Platform, including but not limited
              to videos, text, and graphics, is the exclusive property of
              Shraddha Infosystems. Unauthorized use, reproduction, or
              distribution is strictly prohibited.
              <br />
              <br />
              <strong>6. Data Collection and Privacy</strong>
              <br />
              Shraddha Infinite collects and processes user data, including the
              information provided during registration, to deliver its services.
              By using the Platform, you consent to this data being used in
              compliance with our Privacy Policy.
              <br />
              We do not share your personal data with third parties except for
              essential service providers, such as payment processors.
              <br />
              <br />
              <strong>7. Disclaimers and Limitations of Liability</strong>
              <br />
              <ul>
                <li>
                  The Platform and its services are provided "as is" without
                  warranties of any kind, either express or implied.
                </li>
                <li>
                  Shraddha Infinite is not liable for any indirect, incidental,
                  or consequential damages arising from your use of the
                  Platform.
                </li>
                <li>
                  While we strive to provide uninterrupted service, we do not
                  guarantee that the Platform will be available at all times or
                  free from errors.
                </li>
              </ul>
              <br />
              <br />
              <strong>8. Termination</strong>
              <br />
              Shraddha Infosystems reserves the right to suspend or terminate
              your account if you violate these Terms. Upon termination, your
              access to membership content will be revoked without refund.
              <br />
              <br />
              <strong>9. Governing Law</strong>
              <br />
              These Terms are governed by the laws of India. Any disputes
              arising from these Terms will be subject to the exclusive
              jurisdiction of the courts in Pune, India.
              <br />
              <br />
              <strong>10. Modifications to the Terms</strong>
              <br />
              Shraddha Infosystems may revise these Terms at any time by
              updating this page. Continued use of the Platform after changes
              are made constitutes your acceptance of the revised Terms.
              <br />
              <br />
              Contact Us For any questions or concerns about these Terms, please
              contact us at 02041488999.
              <br />
              <br />
              By registering for an account and using the Platform, you
              acknowledge that you have read, understood, and agreed to these
              Terms and Conditions.
            </Typography>
          </Box>
        </DialogContent>

        <DialogActions
          sx={{ display: "flex", justifyContent: "right", padding: 1 }}
        >
          <Button
            size="small"
            variant="contained"
            onClick={Closedialog}
            sx={{ borderRadius: "10px", background: "black" }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      {/* ========================================================== */}
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "380px",
          padding: "20px 30px 20px 30px",
          borderRadius: "10px",
          backgroundColor: "#ebfafc",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          sx={{ color: "#000", marginBottom: "30px" ,margin:"0", p:"0"}}
        >
          <img
            alt="logo"
            width="250px"
            height="120px"
            style={{ borderRadius: "15px" }}
            src="../../assets/Infinitelogo.png"
          />
        </Typography>

        <Divider style={{ margin: "10px 0" }} />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <SignupTextField
              name="firstName"
              label="First Name"
              variant="outlined"
              fullWidth
              required
              size="small"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SignupTextField
              name="lastName"
              label="Last Name"
              variant="outlined"
              fullWidth
              size="small"
              required
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <SignupTextField
              name="email"
              label="Email"
              variant="outlined"
              fullWidth
              size="small"
              required
              value={userEmail}
              onChange={handleEmailChange}
              // onChange={(e) => setUserEmail(e.target.value)}
              disabled={loading}
              error={!!emailError} // If there's an error, set the error prop to true
            />
            {emailError && (
              <FormHelperText error>{emailError}</FormHelperText> // Show error message below the input
            )}
          </Grid>
          <Grid item xs={12}>
            <SignupTextField
              name="mobileno"
              label="Mobile No"
              variant="outlined"
              fullWidth
              size="small"
              required
              value={mobile}
              onChange={(e) => setUserMobile(e.target.value)}
              disabled={loading}
              helperText={
                CheckNo ||
                (mobile.length < 10 && mobile.length > 0
                  ? "Mobile number must be at least 10 digits."
                  : "")
              }
              error={!!CheckNo || (mobile.length < 10 && mobile.length > 0)}
              inputProps={{
                pattern: "[0-9]*", // Only numeric characters allowed
                inputMode: "numeric", // Numeric keyboard on mobile devices
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <SignupTextField
              label="Company"
              variant="outlined"
              fullWidth
              size="small"
              required
              value={cmpName}
              onChange={(e) => setCmpName(e.target.value)}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <SignupTextField
              name="password"
              label="Password"
              variant="outlined"
              type={showPassword ? "text" : "password"} // Toggle between text and password
              fullWidth
              required
              size="small"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={isValid === false}
              helperText={
                isValid === false
                  ? "Password must be at least 8 characters, including uppercase, lowercase, and special characters."
                  : ""
              }
              disabled={loading}
              sx={{
                "& .MuiInputBase-root": {
                  color: isValid === null ? "black" : isValid ? "green" : "red", // Text color
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor:
                      isValid === null ? "black" : isValid ? "green" : "red", // Border color
                  },
                  "&:hover fieldset": {
                    borderColor:
                      isValid === null ? "#000" : isValid ? "green" : "red", // Border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor:
                      isValid === null ? "#000" : isValid ? "green" : "red", // Focused border color
                  },
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      sx={{
                        color: "black", // Set the icon button color to black
                      }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={termsChecked}
                    onChange={handleCheckboxChange}
                    size="small"
                    sx={{
                      color: "#000",
                      "&.Mui-checked": {
                        color: "#000",
                      },
                    }}
                  />
                }
                label=""
                sx={{
                  color: "#000",
                  "&.Mui-checked": {
                    color: "#000",
                  },
                }}
              />
              <Typography
                sx={{ color: "#000", cursor: "pointer" }}
                onClick={handleClickTc}
              >
                Terms and Conditions
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {error && (
          <Grid item xs={12}>
            <Typography color="error">{error}</Typography>
          </Grid>
        )}

        {message && (
          <Typography color="error" align="center" sx={{ marginTop: 2 }}>
            {message}
          </Typography>
        )}

        <Button
          variant="contained"
          fullWidth
          sx={{
            marginTop: "20px",
            backgroundColor: "#B09246",
            ":hover": { backgroundColor: "#B09246" },
            borderRadius: "10px",
          }}
          onClick={handleRegister}
          disabled={loading}
        >
          {loading ? "Registering..." : "Register"}
        </Button>

        <Divider style={{ margin: "16px 0" }} />

        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ marginTop: "1px" }}
        >
          <Typography variant="h5" sx={{ color: "#000",  m:"0", p:"0" }}>
            Already have an account?{" "}
            <Link to="/" style={{ textDecoration: "none" }}>
              <Typography
                variant="body2"
                sx={{ color: "blue",  display: "inline" }}
              >
                Log in
              </Typography>
            </Link>
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default Signup;
