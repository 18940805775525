import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
  InputAdornment,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from "@mui/x-data-grid";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";

export default function SharaddhaInfiniteMember() {
  const [UsersData, setUsersData] = useState([]);
  const token = localStorage.getItem("shraddhatoken");
  const [userRow, setuserRow] = useState({});
  const [ShowDialog, setShowDialog] = useState(false);
  const [formValues, setFormValues] = useState({
    member_From: "",
    member_To: "",
    user_Email: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const Closedialog = () => setShowDialog(false);
  const handleDateChange = (field, value) => {
    setFormValues((prev) => ({ ...prev, [field]: value }));
  };

  // ============= member update handler code =======

  const handleUpdate = async () => {
    const updateSub = {
      adminEmail: formValues.user_Email,
      memberFrom: formValues.member_From,
      memberTo: formValues.member_To,
    };

    // console.log("updateSub", updateSub);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/shraddha/operation/subUpdate",
        updateSub,
        {
          headers: { Authorization: token },
        }
      );

      if (response) {
        alert(response.data.message);
      }
    } catch (error) {
      alert("Error member Update ", error);
    }
  };

  // =================== handler for send  WhatsApp Messege =====================================

  const handleWhatsApp = async (row) => {
    const TemplatePara = localStorage.getItem("WhatsappTemplate");

    const jsonTemplatePara = JSON.parse(TemplatePara);

    const updateSub = {
      template: jsonTemplatePara.templateText,
      WhatsAppNo: row.mobileNumber,
      parameter1: row[jsonTemplatePara.parameter1],
      parameter2: row[jsonTemplatePara.parameter2],
      parameter3: row[jsonTemplatePara.parameter3],
      parameter4: row[jsonTemplatePara.parameter4],
      parameter5: row[jsonTemplatePara.parameter5],
    };

    console.log("updateSub", updateSub);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/shraddha/operation/whatsappmsg",
        updateSub,
        {
          headers: { Authorization: token },
        }
      );

      if (response) {
        alert("WhatsApp Messege Send succefully");
      }
    } catch (error) {
      alert("Error Send WhatsApp Messege ", error);
    }
  };

  //=================== this handler is  update infinite member =========

  const LedfetchData = async (userRow) => {
    setuserRow(userRow);
    setFormValues({
      member_From: userRow.member_From || "",
      member_To: userRow.member_To || "",
      user_Email: userRow.email || "",
    });
    setShowDialog(true);
  };

  //=================== this handler is  list of  infinite member =========

  const fetchUsersData = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/shraddha/operation/memberList",
        {},
        {
          headers: { Authorization: token },
        }
      );

      const users = Object.values(response.data.rows);
      setUsersData(users);
    } catch (error) {
      alert("Error fetching infinite member ", error);
    }
  };

  useEffect(() => {
    fetchUsersData();
  }, []);

  const filteredUsers = UsersData.filter((user) => {
    const searchLower = searchQuery.toLowerCase();
    return (
      (user.member_ContactName &&
        user.member_ContactName.toLowerCase().includes(searchLower)) ||
      (user.member_Email &&
        user.member_Email.toLowerCase().includes(searchLower)) ||
      (user.member_CmpName &&
        user.member_CmpName.toLowerCase().includes(searchLower))
    );
  });

  const IMemberListRow = filteredUsers.map((user) => ({
    id: user.member_MobNo,
    name: user.member_ContactName,
    email: user.member_Email,
    CmpName: user.member_CmpName,
    mobileNumber: user.member_MobNo,
    member_AssignedTo: user.member_AssignedTo,
    member_From: user.member_From
      ? new Date(user.member_From).toISOString().split("T")[0]
      : "",
    member_To: user.member_To
      ? new Date(user.member_To).toISOString().split("T")[0]
      : "",
    member_InvoiceNo: user.member_InvoiceNo,
    member_UTR: user.member_UTR,
    member_Amount: user.member_Amount,
    IsRegister: user.admin_Id ? "Yes" : "No",
  }));
  const columns = [
    {
      field: "",
      headerName: "SR No",
      headerAlign: "center",
      align: "center",
      width: 80,
      sortable: false,
      resizable: true, // Enable resizing
      disableColumnMenu: true,
      renderCell: (params) => params.api.getRowIndex(params.id) + 1,
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      align: "center",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "center",
      align: "center",
      width: 300,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "CmpName",
      headerName: "Company Name",
      headerAlign: "center",
      align: "center",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "member_AssignedTo",
      headerName: "Assigned To",
      headerAlign: "center",
      align: "center",
      width: 300,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "member_From",
      headerName: "Member From",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "member_To",
      headerName: "Member To",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },

    {
      field: "member_InvoiceNo",
      headerName: "Invoice No",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },

    {
      field: "member_UTR",
      headerName: "member UTR No",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },

    {
      field: "member_Amount",
      headerName: "Amount",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "IsRegister",
      headerName: "Register Or Not",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },

    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 200, // Adjusted width to fit both buttons
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div
            style={{ display: "flex", gap: "8px", justifyContent: "center" }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "lightblue", // Set background color to light blue
                color: "white", // Set text color to white
                "&:hover": {
                  backgroundColor: "skyblue", // Optional: Slightly darker blue on hover
                },
              }}
              size="small"
              type="button"
              onClick={() => LedfetchData(params.row)}
            >
              Update
            </Button>
            <Button
              variant="contained"
              size="small"
              type="button"
              sx={{
                backgroundColor: "green", // Set background color to green
                color: "white", // Set text color to white
                "&:hover": {
                  backgroundColor: "darkgreen", // Optional: Darker green on hover
                },
              }}
              onClick={() => handleWhatsApp(params.row)}
            >
              WhatsApp
            </Button>
          </div>
        );
      },
    },
  ];

  //================== whasApp templete code  ======================

  const [open, setOpen] = useState(false);
  const handleWtsappTemOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [templateData, setTemplateData] = useState({
    templateText: "",
    parameter1: "",
    parameter2: "",
    parameter3: "",
    parameter4: "",
    parameter5: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTemplateData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    const jsonData = JSON.stringify(templateData);
    localStorage.setItem("WhatsappTemplate", jsonData);
    handleClose();
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          paddingTop: 8,
          paddingLeft: 3,
          paddingRight: 3,
          marginLeft: "240px",
          marginTop: 4,
          backgroundColor: "#f4f6f7",
          borderRadius: "10px",
        }}
      >
        {/* ================== Show Single member Data ===================== */}
        <Box>
          <Dialog
            open={ShowDialog}
            sx={{
              "& .MuiDialog-paper": {
                backgroundColor: "#f0f0f0",
                padding: 2,
              },
            }}
          >
            <DialogTitle
              variant="h3"
              gutterBottom
              p={0}
              m={0}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "#000",
              }}
            >
              Member Details
              <CancelIcon
                sx={{
                  cursor: "pointer",
                  fontSize: "24px", // Adjust size if necessary
                }}
                onClick={Closedialog}
              />
            </DialogTitle>
            <DialogContent>
              {/* Ledger Information Section */}
              <Grid container spacing={2} mt={0}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Name"
                    name="Name"
                    size="small"
                    fullWidth
                    value={userRow.name}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .MuiInputBase-input": {
                        color: "black", // Input text color
                      },
                      "& .MuiInputLabel-root": {
                        color: "black", // Label text color
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black", // Default border color
                        },
                        "&:hover fieldset": {
                          borderColor: "black", // Hover border color
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black", // Focused border color
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Organization"
                    name="Organization"
                    size="small"
                    fullWidth
                    value={userRow.CmpName}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .MuiInputBase-input": {
                        color: "black", // Input text color
                      },
                      "& .MuiInputLabel-root": {
                        color: "black", // Label text color
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black", // Default border color
                        },
                        "&:hover fieldset": {
                          borderColor: "black", // Hover border color
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black", // Focused border color
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={0}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email"
                    name="Email"
                    size="small"
                    fullWidth
                    value={userRow.email}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .MuiInputBase-input": {
                        color: "black", // Input text color
                      },
                      "& .MuiInputLabel-root": {
                        color: "black", // Label text color
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black", // Default border color
                        },
                        "&:hover fieldset": {
                          borderColor: "black", // Hover border color
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black", // Focused border color
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Mobile No."
                    name="MoNumber"
                    size="small"
                    fullWidth
                    value={userRow.mobileNumber}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .MuiInputBase-input": {
                        color: "black", // Input text color
                      },
                      "& .MuiInputLabel-root": {
                        color: "black", // Label text color
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black", // Default border color
                        },
                        "&:hover fieldset": {
                          borderColor: "black", // Hover border color
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black", // Focused border color
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ marginY: 2 }} />
              <Grid container spacing={2} mt={0}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Member From"
                    type="date"
                    value={formValues.member_From}
                    onChange={(e) =>
                      handleDateChange("member_From", e.target.value)
                    }
                    size="small"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .MuiInputBase-input": {
                        color: "black", // Input text color
                      },
                      "& .MuiInputLabel-root": {
                        color: "black", // Label text color
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black", // Default border color
                        },
                        "&:hover fieldset": {
                          borderColor: "black", // Hover border color
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black", // Focused border color
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Member To"
                    type="date"
                    value={formValues.member_To}
                    onChange={(e) =>
                      handleDateChange("member_To", e.target.value)
                    }
                    size="small"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .MuiInputBase-input": {
                        color: "black", // Input text color
                      },
                      "& .MuiInputLabel-root": {
                        color: "black", // Label text color
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black", // Default border color
                        },
                        "&:hover fieldset": {
                          borderColor: "black", // Hover border color
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black", // Focused border color
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "right",
                padding: 1,
              }}
            >
              <Button
                color="success"
                size="small"
                variant="contained"
                onClick={handleUpdate}
                sx={{
                  backgroundColor: "green", // Set background color to green
                  color: "white", // Set text color to white
                  "&:hover": {
                    backgroundColor: "darkgreen", // Optional: Darker green on hover
                  },
                }}
              >
                Update
              </Button>
            </DialogActions>
          </Dialog>
        </Box>

        {/* ===================================================== */}

        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h2" color="#000">
              Infinite Members
            </Typography>
            {/* Search Bar */}
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{
                width: "300px",
                marginBottom: "20px",
                "& .MuiInputBase-input": {
                  color: "black", // Input text color
                },
                "& .MuiInputLabel-root": {
                  color: "black", // Label text color
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "black", // Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "black", // Hover border color
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "black", // Focused border color
                  },
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      sx={{
                        color: "black",
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box
            height="74vh"
            sx={{
              overflowX: "auto",
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
                color: "black", // Set cell text color to black
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#E9F3F5",
                borderBottom: "none",
                cursor: "default",
                color: "black", // Set header text color to black
              },
              "& .MuiDataGrid-columnHeader:focus": {
                outline: "none",
                boxShadow: "none",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                outline: "none",
                boxShadow: "none",
              },
              "& .MuiDataGrid-columnHeader:focus-visible": {
                outline: "none",
                boxShadow: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "#fff", // Background color for the scroller
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: "#E9F3F5",
              },
              "& .MuiDataGrid-overlay": {
                backgroundColor: "#ffffff", // Set overlay background color when table is empty
                color: "gray", // Optional: set text color for empty state message
              },
            }}
          >
            <DataGrid
              rows={IMemberListRow}
              columns={columns}
              disableColumnSort={true}
              stickyHeader
              aria-label="sticky table"
            />
          </Box>
          <Box
            border="1px solid #ccc"
            borderRadius="8px"
            padding="16px"
            boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
            mt="5vh"
            width="16vw"
          >
            <Button
              variant="contained"
              sx={{
                fontWeight: "bold",
                fontSize: "14px",
                padding: "6px 16px",
                backgroundColor: "#304251",
                ":hover": { backgroundColor: "#304251" },
                color: "#fff",
              }}
              onClick={handleWtsappTemOpen}
            >
              WhatsApp Template
            </Button>

            {/* ========================================================================================= */}
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
              <DialogTitle
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#000",
                }}
              >
                WhatsApp Template
                <CancelIcon
                  sx={{ cursor: "pointer", fontSize: "24px" }}
                  onClick={handleClose}
                />
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  {[
                    {
                      label: "Enter your template text",
                      name: "templateText",
                      type: "text",
                    },
                    {
                      label: "Parameter 1",
                      name: "parameter1",
                      type: "dropdown",
                    },
                    {
                      label: "Parameter 2",
                      name: "parameter2",
                      type: "dropdown",
                    },
                    {
                      label: "Parameter 3",
                      name: "parameter3",
                      type: "dropdown",
                    },
                    {
                      label: "Parameter 4",
                      name: "parameter4",
                      type: "dropdown",
                    },
                    {
                      label: "Parameter 5",
                      name: "parameter5",
                      type: "dropdown",
                    },
                  ].map((field, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                      {field.type === "text" ? (
                        <TextField
                          label={field.label}
                          name={field.name}
                          value={templateData[field.name]}
                          onChange={handleChange}
                          size="small"
                          fullWidth
                          sx={{
                            "& .MuiInputBase-input": { color: "black" },
                            "& .MuiInputLabel-root": { color: "black" },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": { borderColor: "black" },
                              "&:hover fieldset": { borderColor: "black" },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                          }}
                        />
                      ) : (
                        <TextField
                          select
                          label={field.label}
                          name={field.name}
                          value={templateData[field.name]}
                          onChange={handleChange}
                          size="small"
                          fullWidth
                          sx={{
                            "& .MuiInputBase-input": { color: "black" },
                            "& .MuiInputLabel-root": { color: "black" },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": { borderColor: "black" },
                              "&:hover fieldset": { borderColor: "black" },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                          }}
                        >
                          {[
                            "name",
                            "email",
                            "CmpName",
                            "mobileNumber",
                            "member_AssignedTo",
                            "member_From",
                            "member_To",
                            "member_InvoiceNo",
                            "member_UTR",
                            "member_Amount",
                            "IsRegister",
                          ].map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "right", padding: 1 }}>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    padding: "6px 16px",
                    backgroundColor: "#304251",
                    ":hover": { backgroundColor: "#304251" },
                    color: "#fff",
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
      </Box>
    </>
  );
}
