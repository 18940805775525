import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Divider,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material";
import axios from "axios";

// Custom styled TextField component to match the style
const SignupTextField = styled(TextField)({
  "& .MuiInputLabel-root": { color: "#000" },
  "& .MuiInputLabel-root.Mui-focused": { color: "#000" },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#000",
    },
    "&:hover fieldset": {
      borderColor: "#000",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#357AE8",
    },
  },
  "& .MuiOutlinedInput-input": { color: "#000" },
});

const ForggotPwd = () => {
  const [email, setUsername] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");

  //=================================

  // Email validation function
  const validateEmail = (email) => {
    // Regular expression for basic email format validation
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setUsername(value);

    if (!validateEmail(value)) {
      setEmailError("Please enter a valid email Format.");
    } else {
      setEmailError(""); // Clear error if email is valid
    }
  };

  //=====================================

  const handleFp = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/auth/forgot-password",
        {
          email,
        }
      );
      setMessage(response.data.message);
    } catch (error) {
      setMessage(`${error.response?.data?.message || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundImage: "url('../../assets/sign up BG_equal.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        // padding: 2,
      }}
    >
      <Paper
        elevation={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "400px",
          borderRadius: "10px",
          padding: "30px 30px 30px 30px",
          backgroundColor:"white"
        }}
      >
        <Typography
          variant="h4"
          align="center"
          sx={{ color: "#000", marginBottom: "0px" }}
        >
          
          <img
            alt="logo"
            width="250px"
            height="120px"
            style={{ borderRadius: "15px" }}
            src="../../assets/Infinitelogo.png"
          />
        </Typography>

        <Divider style={{ margin: "16px 0" }} />

        <SignupTextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={email}
          onChange={handleEmailChange}
          // onChange={(e) => setUserEmail(e.target.value)}
          disabled={loading}
          error={!!emailError} // If there's an error, set the error prop to true
        />
        {emailError && (
          <FormHelperText error>{emailError}</FormHelperText> // Show error message below the input
        )}

        {message && <Typography color="succes">{message}</Typography>}
        <Button
          variant="contained"
          fullWidth
          sx={{
            marginTop: "20px",
            marginBottom: "20px",
            backgroundColor: "#B09246",
            ":hover": { backgroundColor: "#B09246" },
            borderRadius: "10px",
          }}
          onClick={handleFp}
          disabled={loading}
        >
          Send mail
        </Button>

        <Typography variant="body2" sx={{ color: "black", fontSize: "15px" }}>
          Password reset instructions will be emailed to you.
        </Typography>
      </Paper>
    </Box>
  );
};

export default ForggotPwd;
