import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeIcon from "@mui/icons-material/Home";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MenuOpen from "@mui/icons-material/MenuOpen";
import ApprovalIcon from "@mui/icons-material/Approval";
import SecondApprov from "@mui/icons-material/HowToReg";
import VerifiedIcon from "@mui/icons-material/Verified";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AddHomeWorkRoundedIcon from "@mui/icons-material/AddHomeWorkRounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SourceIcon from "@mui/icons-material/Source";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import StoreIcon from "@mui/icons-material/Store";
import PaymentIcon from "@mui/icons-material/Payment";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import AddCardIcon from "@mui/icons-material/AddCard";
import TaskIcon from "@mui/icons-material/Task";
import BarChartIcon from "@mui/icons-material/BarChart";
import axios from "axios";
import EventNoteIcon from "@mui/icons-material/EventNote";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { useNavigate } from "react-router-dom";
const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const token = localStorage.getItem("token");
  const cmpid = localStorage.getItem("cmpid");

  // State variables for visibility
  const [showPrimaryApproval, setShowPrimaryApproval] = useState(true);
  const [showSecondaryApproval, setShowSecondaryApproval] = useState(true);
  const [showFinalApproval, setshowFinalApproval] = useState(true);
  const [showAdminApproval, setshowAdminApproval] = useState(false);
  const [showUserConfigApproval, setshowUserConfigApproval] = useState(false);
  const [showAllUsers, setshowAllUsers] = useState(false);


  useEffect(() => {
    const updateStateBasedOnScreenSize = () => {
      const isSmall = window.matchMedia("(max-width: 640px)").matches; // Tailwind SM breakpoint
      const isMedium = window.matchMedia(
        "(min-width: 641px) and (max-width: 1024px)"
      ).matches; // Tailwind MD breakpoint
      const isExtraLarge = window.matchMedia("(min-width: 1281px)").matches; // Tailwind XL breakpoint

      if (isExtraLarge) {
        setIsCollapsed(false);
      } else if (isMedium || isSmall) {
        setIsCollapsed(true);
      }
    };

    updateStateBasedOnScreenSize(); // Initialize state on mount

    window.addEventListener("resize", updateStateBasedOnScreenSize); // Update state on resize

    return () => {
      window.removeEventListener("resize", updateStateBasedOnScreenSize); // Cleanup
    };
  }, []);

  useEffect(() => {
    const role = localStorage.getItem("Role");

    if (role === "admin") {
      setshowUserConfigApproval(true);
      setshowAllUsers(true);
      setshowAdminApproval(true);
    }
    // ==================================

    // if (AppModCmpId === cmpid && ApprovalMod === 1) {
    //   setshowAppMod(true);
    // }
    // else if(AppModCmpId === cmpid && ApprovalMod === 0)
    // {
    //   setshowAppMod(false);
    // }

    const fetchData = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/profile/approvalPerm",
          {},
          {
            headers: { Authorization: token },
          }
        );

        const UserNameData = Object.values(response.data);

        const permissionLevels = UserNameData.map(
          (item) => item.permission_Level
        );

        const permissioncmpId = UserNameData.map((item) => item.cmp_id);

        if (
          permissionLevels.includes("Primary") &&
          permissioncmpId.includes(cmpid)
        ) {
          setShowPrimaryApproval(true);
        } else {
          setShowPrimaryApproval(false);
        }

        if (
          permissionLevels.includes("Secondary") &&
          permissioncmpId.includes(cmpid)
        ) {
          setShowSecondaryApproval(true);
        } else {
          setShowSecondaryApproval(false);
        }

        if (
          permissionLevels.includes("Final") &&
          permissioncmpId.includes(cmpid)
        ) {
          setshowFinalApproval(true);
        } else {
          setshowFinalApproval(false);
        }
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };

    fetchData();
  });

  //========================= check Approval m0dule Enable or not  ================================================
  // const [showAppMod, setshowAppMod] = useState();
  // const [AppModCmpId, setAppModCmpId] = useState([]);
  // const [ApprovalMod, setApprovalMod] = useState([]);

  // const fetchDataModule = async () => {
  //   try {
  //     const response = await axios.post(
  //       process.env.REACT_APP_API_URL + "/company/list",
  //       {},
  //       {
  //         headers: { Authorization: token },
  //       }
  //     );

  //     const UserListData = Object.values(response.data.users);
  //     setAppModCmpId(UserListData[0].Cmp_Id);
  //     setApprovalMod(UserListData[0].approval_Module);
  //   } catch (error) {
  //     alert("Something went Wrong");
  //   }
  // };

  //============================================================================================

  const [isDataOpen, setIsDataOpen] = useState(false); // State to manage collapse
  const [isMarketPlace, setMarketPlace] = useState(false);
  const [isVchOpen, setIsVchOpen] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);

  const handleDataToggle = () => {
    setIsDataOpen(!isDataOpen); // Toggle the open state
  };
  const handlvchToggle = () => {
    setIsVchOpen(!isVchOpen); // Toggle the open state
  };

  const handlMarketToggle = () => {
    setMarketPlace(!isMarketPlace); // Toggle the open state
  };

  const handlReportToggle = () => {
    setIsReportOpen(!isReportOpen); // Toggle the open state
  };

  //============ navigate to pages ============

  const handleEvent = () => {
    navigate("/ShraddhaEvents");
  };

  const handlSupport = () => {
    navigate("/LearningAndEvents");
  };

  //======================================
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          "& .pro-sidebar-inner": {
            background: `${colors.primary[400]} !important`,
            boxShadow: "2px 0 2px -2px rgba(0, 0, 0, 0.5)",
            marginTop: "42px",
            position: isCollapsed ? "fixed" : "fixed",
            height: isCollapsed ? "75.5vh" : "75.5vh",
            overflowY: "auto",
            top: isCollapsed ? "35px" : "35px",
            left: 0,
            width: isCollapsed ? "90px" : "270px",
            zIndex: isCollapsed ? 999 : 999,
            transition: "all 0.3s ease",
            flexDirection: "column",
          },
          "& .pro-icon-wrapper": {
            backgroundColor: "transparent !important",
          },
          "& .pro-inner-item": {
            color: `${colors.grey[400]}!important`,
          },
          "& .pro-inner-item:hover": {
            color: "#CBAD5B !important", //"#868dfb !important",
          },
          "& .pro-menu-item": {
            color: "#6870fa !important",
            borderRadius: "10px",
            fontSize: "10px",
          },
          "& .pro-menu-item.active": {
            color: "#6870fa !important",
            backgroundColor: `${colors.blueAccent[900]}!important`,
            borderRadius: "10px",
          },
        }}
      >
        <ProSidebar collapsed={isCollapsed}>
          <Menu iconShape="square">
            {/*LOGO AND MENU ICON */}
            <MenuItem
              onClick={() => setIsCollapsed(!isCollapsed)}
              icon={isCollapsed ? <MenuOutlinedIcon /> : <MenuOpen />}
              style={{
                // margin: "10px 0 20px 0",
                margin: 0,
                padding: 0,
                color: colors.grey[100],
              }}
            ></MenuItem>

            {/* Menu Items */}

            <Box paddingLeft={isCollapsed ? undefined : "10%"}>
              <div
                style={{
                  marginLeft: "0px",
                  color: "#868dfb",
                  alignItems: "left",
                  justifyContent: "left",
                }}
              >
                <Item
                  title="Dashboard"
                  to="/Dashboard"
                  icon={<HomeIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  //marginTop:"15px"
                }}
                onClick={handleDataToggle}
              >
                <SourceIcon
                  style={{ marginLeft: "25px", color: colors.primary[100] }}
                />
                {/* Add your icon here */}
                <Typography
                  variant="h4"
                  paddingLeft={isCollapsed ? "20%" : "0px"}
                  color={colors.primary[100]}
                  sx={{
                    m: "15px 0 5px 20px",
                    cursor: "pointer",
                  }}
                >
                  Data
                </Typography>

                {isDataOpen ? ( // Show up arrow when expanded
                  <ExpandLessIcon
                    style={{ marginLeft: "auto", color: colors.primary[100] }}
                  />
                ) : (
                  // Show down arrow when collapsed
                  <ExpandMoreIcon
                    style={{ marginLeft: "auto", color: colors.primary[100] }}
                  />
                )}
              </div>
              {isDataOpen && ( // Conditionally render items
                <>
                  <div
                    style={{
                      marginLeft: "20px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Item
                      title="Tally Companies"
                      to="/TallyCompanies"
                      icon={<AddHomeWorkRoundedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item
                      title="Tally Ledger"
                      to="/Ledger"
                      icon={<AddHomeWorkRoundedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    {showAllUsers && (
                      <Item
                        title="All Users"
                        to="/team"
                        icon={<PeopleOutlinedIcon />}
                        selected={selected}
                        setSelected={setSelected}
                      />
                    )}
                  </div>
                </>
              )}

              {/* ======================= market place menu items ======================== */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={handlMarketToggle}
              >
                <StoreIcon
                  style={{ marginLeft: "25px", color: colors.primary[100] }}
                />
                {/* Add your icon here */}
                <Typography
                  paddingLeft={isCollapsed ? "20%" : "0px"}
                  variant="h4"
                  color={colors.primary[100]} // Ensure the text color is black
                  sx={{
                    m: "15px 0 5px 20px",
                    cursor: "pointer",
                  }}
                >
                  Market Place
                </Typography>
                {isMarketPlace ? ( // Show up arrow when expanded
                  <ExpandLessIcon
                    style={{ marginLeft: "auto", color: colors.primary[100] }}
                  />
                ) : (
                  // Show down arrow when collapsed
                  <ExpandMoreIcon
                    style={{ marginLeft: "auto", color: colors.primary[100] }}
                  />
                )}
              </div>
              {isMarketPlace && (
                <>
                  <div
                    style={{
                      marginLeft: "20px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Item
                      title="Module List"
                      to="/MarketPlace"
                      icon={<AddHomeWorkRoundedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </div>
                </>
              )}
              {/* =============== voucher Approval ================================ */}
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handlvchToggle}
                >
                  <VerifiedIcon
                    style={{ marginLeft: "25px", color: colors.primary[100] }}
                  />
                  <Typography
                    paddingLeft={isCollapsed ? "20%" : "0px"}
                    color={colors.primary[100]}
                    sx={{ m: "15px 0 5px 20px", fontSize: "18.5px" }}
                  >
                    Voucher Approval
                  </Typography>
                  {isVchOpen ? ( // Show up arrow when expanded
                    <ExpandLessIcon
                      style={{
                        marginLeft: "auto",
                        color: colors.primary[100],
                      }}
                    />
                  ) : (
                    // Show down arrow when collapsed
                    <ExpandMoreIcon
                      style={{
                        marginLeft: "auto",
                        color: colors.primary[100],
                      }}
                    />
                  )}
                </div>

                {/* Conditionally render content if isVchOpen is true */}
                {isVchOpen && (
                  <div
                    style={{
                      marginLeft: "20px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    {showPrimaryApproval && (
                      <Item
                        title="Primary Approval"
                        to="/PrimaryApprov"
                        icon={<ApprovalIcon />}
                        selected={selected}
                        setSelected={setSelected}
                      />
                    )}

                    {showSecondaryApproval && (
                      <Item
                        title="Secondary Approval"
                        to="/SecondaryApprov"
                        icon={<SecondApprov />}
                        selected={selected}
                        setSelected={setSelected}
                      />
                    )}

                    {showFinalApproval && (
                      <Item
                        title="Final Approval"
                        to="/FinalApprov"
                        icon={<TaskAltIcon />}
                        selected={selected}
                        setSelected={setSelected}
                      />
                    )}

                    {showAdminApproval && (
                      <Item
                        title="Admin Report"
                        to="/AdminList"
                        icon={<AssignmentIndIcon />}
                        selected={selected}
                        setSelected={setSelected}
                      />
                    )}

                    {showUserConfigApproval && (
                      <Item
                        title="Users Config"
                        to="/ApprovalModuleUsers"
                        icon={<AccountCircleIcon />}
                        selected={selected}
                        setSelected={setSelected}
                      />
                    )}
                  </div>
                )}
              </div>
              {/* ================= reports   ================================ */}
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handlReportToggle}
                >
                  <BarChartIcon
                    style={{ marginLeft: "25px", color: colors.primary[100] }}
                  />
                  <Typography
                    variant="h4"
                    paddingLeft={isCollapsed ? "20%" : "0px"}
                    color={colors.primary[100]}
                    sx={{ m: "15px 0 5px 20px", cursor: "pointer" }}
                  >
                    Reports
                  </Typography>
                  {isReportOpen ? ( // Show up arrow when expanded
                    <ExpandLessIcon
                      style={{
                        marginLeft: "auto",
                        color: colors.primary[100],
                      }}
                    />
                  ) : (
                    // Show down arrow when collapsed
                    <ExpandMoreIcon
                      style={{
                        marginLeft: "auto",
                        color: colors.primary[100],
                      }}
                    />
                  )}
                </div>

                {/* Conditionally render content if isReportOpen is true */}
                {isReportOpen && (
                  <div
                    style={{
                      marginLeft: "20px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Item
                      title="Vocher Entry"
                      to="/VoucherEntry"
                      icon={<TaskIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />

                    <Item
                      title="Payable Report"
                      to="/PayableReport"
                      icon={<PaymentIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />

                    <Item
                      title="Receivable Report"
                      to="/ReceivableReport"
                      icon={<AssuredWorkloadIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />

                    <Item
                      title="Credtor's Score Card"
                      to="/CreditorScore"
                      icon={<AddCardIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />

                    <Item
                      title="Debtor's Score Card"
                      to="/DebitorScore"
                      icon={<CreditScoreIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </div>
                )}
              </div>
            </Box>
          </Menu>
        </ProSidebar>
      </Box>
      <Box
        sx={{
          background: `${colors.primary[400]} !important`,
          boxShadow: "2px 0 2px -2px rgba(0, 0, 0, 0.5)",
          position: "fixed",
          bottom: 0,
          left: 0,
          width: isCollapsed ? "90px" : "270px",
          display: isCollapsed ? "" : "flex",
          flexDirection: isCollapsed ? "column" : "row", // Keep row direction for icons and text horizontally
          alignItems: "center", // Center the items vertically
          padding: "10px", // Add padding for spacing
          zIndex: 1000, // Ensure it's above other content
          height: "13.5vh", // Set the height
        }}
      >
        {/* Event Icon and Text */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginRight: "20px",
            marginLeft: "20px",
            cursor: "pointer",
          }}
          onClick={handleEvent}
        >
          <EventNoteIcon style={{ color: "#B09246" }} />
          {!isCollapsed && (
            <Typography sx={{ marginLeft: "8px", color: "#B09246" }}>
              Events
            </Typography>
          )}
        </Box>

        {/* Support Icon and Text */}
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={handlSupport}
        >
          <SupportAgentIcon
            style={{ color: "#B09246", marginLeft: isCollapsed ? "20px" : "0" }}
          />
          {!isCollapsed && (
            <Typography sx={{ marginLeft: "8px", color: "#B09246" }}>
              Support
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
